<template>
  <div class="contain" v-if="courseInfo">
    <el-breadcrumb class="m_t_15" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/liveSearch?type=more' }">直播公开课</el-breadcrumb-item>
      <el-breadcrumb-item>资源详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="top flex m_tb_20 br_5">
      <div class="img-box">
        <img class="image br_5"
             :src="imgToRespomse(courseInfo.coverUrl)"
             alt="">
      </div>
      <div class="info-box flex_col m_l_08 p_in_20">
        <div class="flex1">
          <div class="flex ">
            <div class="flex1 f35 bold txt_light_3 flex fs_18">{{ courseInfo.name }}</div>
          </div>
          <div>
            <div class="teacher flex f25 txt_light_6 p_tb_04 align fs_16">
              <span class="fs_14 m_l_08">课程讲师：</span>
              {{ courseInfo.teacherName }}
            </div>
            <div v-if="courseInfo.isSubscribe == 1" class="number flex f25 txt_light_6 p_tb_04 align fs_16">
              <span class="fs_14 m_l_08">报名人数：</span>
              <span style="color: #3888F7;">{{ courseInfo.subNumber }}</span>/{{ courseInfo.maximum }}
            </div>
            <div class="time flex f25 txt_light_6 p_tb_04 align fs_16">
              <span class="fs_14 m_l_08">开课时间：</span>
              {{ courseInfo.courseStartTime }}
              至
              {{ courseInfo.courseEndTime ? courseInfo.courseEndTime.slice(-8) : '' }}
            </div>
            <div v-if="courseInfo.isSubscribe == 1" class="time flex f25 txt_light_6 p_tb_04 align fs_16">
              <span class="fs_14 m_l_08">报名截止：</span>
              {{ courseInfo.endTime }}
            </div>
            <div class="flex txt_light_6 p_tb_04 align"
                 v-if="courseInfo.subscribeType == 1 && courseInfo.beginState == 2"
                 style="font-size: 1.4rem;margin-left: 5px; color: red;">
              注：报名截止后，不能取消报名！
              <span class="pointer" style="border-bottom: 1px solid red;" @click="drump()">我的报名课程</span>
            </div>
          </div>
        </div>
        <div class="flex" v-if="courseInfo.isSubscribe == 1">
          <div class="btn c_fff fs_16 pointer" v-if="courseInfo.subscribeType == 1 && courseInfo.beginState == 2"
               @click="cancelSinup(courseInfo)">
            取消报名
          </div>
          <div class="btn c_fff fs_16 pointer" v-if="courseInfo.subscribeType == 2 && courseInfo.beginState == 2"
               @click="open(courseInfo)">
            立即报名
          </div>
          <div class="btn c_fff fs_16 pointer" v-if="courseInfo.subscribeType == -1"
               @click="open(courseInfo)">
            立即报名
          </div>
        </div>
      </div>
      <div class="tag c_fff" :class="{'living': courseInfo.beginState == 0}">{{ courseInfo.beginState == 0 ? '直播中' : '未开始' }}</div>
    </div>
    <!--      简介：-->
    <div class="course-body bg_fff p_in_20 fs_16 br_5">
      <div class="fs_16 fw_bold">
        <div>课程介绍</div>
        <div class="line m_tb_5 m_b_10"></div>
      </div>
      <div class="course_details" v-html="courseInfo.details"></div>
    </div>
  </div>
</template>

<script>
import shoucangSelect1 from '@/assets/img/avatar_01.gif'
import shoucang from '@/assets/img/shoucang.png'
import shoucangSelect from '@/assets/img/shoucang_select.png'
import {liveDetail_api, msubscribe_api, subscribeCancel_api} from '@/api/course/subscribe'
import {saveCollection_api, cancelCollection_api} from '@/api/mine'
import {mapGetters} from "vuex";
import {sessionGet} from "@/utils/local";
import defaultImg from '@/assets/image/default.png'
import {imgToRespomse} from '@/utils/imgToResponse'

export default {
  name: "order",
  data() {
    return {
      shoucangSelect1,
      shoucang,
      shoucangSelect,
      courseInfo: {},
      courseId: '',
      statusT: true,
      loading: true,
      defaultImg
    }
  },
  computed: {
    ...mapGetters({isLogin: "isLogin"}),
  },
  created() {
    this.userInfo = sessionGet('userInfo')
    this.courseId = this.$route.query.courseId
    this.getCourseInfo(this.courseId)
  },
  methods: {
    imgToRespomse(url) {
      return imgToRespomse(url)
    },
    // 获取直播课程详情
    async getCourseInfo(courseId) {
      await liveDetail_api({id: courseId}).then(res => {
        if (res.code == 200) {
          this.courseInfo = res.data
          this.loading = false
        }
      })
    },
    getNow(s) {
      return s < 10 ? '0' + s : s;
    },
    getHu() {
      let d = new Date()
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      let h = d.getHours()
      let m = d.getMinutes()
      let ms = d.getSeconds()
      return year + '-' + this.getNow(month) + '-' + this.getNow(day) + ' ' + this.getNow(h) + ':' + this.getNow(m) + ':' + this.getNow(ms)
    },
    //提示框
    open(item) {
      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        this.$router.push('/login')
        return
      }
      if (item.beTeacher == 1 && this.userInfo.role_name != 'teacher') {
        this.$message.warning('您不符合报名要求,报名只能是教师!')
        return
      }
      if (item.beTeacher == 2 && this.userInfo.role_name != 'student') {
        this.$message.warning('您不符合报名要求，报名只能是学生!')
        return
      }


      if (item.beType == 1) {
        if (item.deptId != this.userInfo.dept_id) {
          return this.$message.warning('校本课程，非本校学生不能参与课程报名!')
        }
      }
      if (this.userInfo.user_id == item.createUserId) {
        return this.$message.warning('不能报名自己创建的直播课!')
      }
      if (item.maximum == item.subNumber) {
        return this.$message.warning('直播课报名人数已满!')
      }

      this.$confirm('请确认是否预约课程《' + item.name + '》，在报名截止时间之后将不能退选。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });
        this.getMsubscribe(item)

      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
        return
      });
    },


    //直播课报名
    async getMsubscribe(item) {

      if (this.statusT == true) {
        this.statusT = false
        if (item.endTime > this.getHu()) {
          this.loading = true
          await msubscribe_api({id: item.id}).then(res => {
            this.statusT = true
            this.loading = false
            if (res.code == 200) {
              this.$message.success('报名成功')
              this.getCourseInfo(this.courseId)
            }
          })
        } else {
          this.statusT = true
          this.$message.warning('直播课报名截止，不能参与报名')
        }
      } else {
        this.$message.warning('请勿重复操作')

      }
    },
    //取消前面确认框
    cancelSinup(item) {
      this.$confirm('请确认是否取消报名-' + item.name + '-直播课,该直播课只能取消报名3次!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getSubscribeCancel(item)
      }).catch(() => {
        return
      });
    },

    //直播课取消报名
    async getSubscribeCancel(item) {

      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        return this.$router.push({path: '/login'})
      }

      if (this.statusT == true) {
        this.statusT = false
        if (item.endTime > this.getHu()) {
          this.loading = true
          await subscribeCancel_api({id: item.subscribeId}).then(res => {
            this.statusT = true
            this.loading = false
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.getCourseInfo(this.courseId)
            } else {
              this.$message.warning(res.msg);
            }
          })
        } else {
          this.statusT = true
          this.$message.warning('超过报名截止时间，不能取消报名')
          return false
        }
      } else {
        this.$message.warning('请勿重复操作')
      }

    },
    // 点击收藏
    async collection(id) {
      if (!this.userInfo) {
        this.$message.warning('请先登录!')
        return this.$router.push({path: '/login'})
      }
      await saveCollection_api({resourcesId: id}).then(res => {
        if (res.code == 200) {
          this.getCourseInfo(this.courseId)
        }
      })
    },
    //取消收藏
    async cancelCollection(id) {
      await cancelCollection_api({id}).then(res => {
        if (res.code == 200) {
          this.getCourseInfo(this.courseId)
        }
      })
    },

    // 跳转到我的报名课程
    drump() {
      let userInfo = sessionGet('userInfo')
      if (userInfo.role_name == 'teacher') {
        this.$router.push('/mineTeacher/signUp')
      } else {
        this.$router.push('/mine/student/signUp')
      }

    },
  },

}
</script>

<style scoped>

.flex {
  display: flex;
}

.flex_col {
  flex-direction: column
}

.flex1 {
  flex: 1;
}

.f35 {
  font-size: 3em;
}

.f25 {
  font-size: 1.7rem
}

.bold {
  font-weight: bold;
}

.txt_light_3 {
  color: #555;
}

.txt_light_6 {
  color: #888;
}

.m_l_08 {
  margin-left: 1.8em;
}

.top {
  width: 100%;
  box-sizing: border-box;
  min-height: 300px;
  background: #fff;
  position: relative;
}

.img-box {
  width: 500px;
  height: 300px;
  border-radius: 10px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contain {
  width: 1200px;
  margin: 0 auto;
}


.bg_light {
  background: #f56c6c;
}


.p_tb_04 {
  padding: 0.3em 0 0.3em 0;
  box-sizing: border-box;
}


.align {
  align-items: center
}


.course_details {
  line-height: 2.5rem;
}

::deep .course_details img {
  max-width: 100%;
  margin: auto;
  display: block;
}

.info-box {
  width: 55%;
}

.teacher::before {
  content: url("../../assets/image/geren.png");
  position: absolute;
  margin-top: 5px;
}

.number::before {
  content: url("../../assets/image/duoren.png");
  position: absolute;
  margin-top: 5px;
}

.time::before {
  content: url("../../assets/image/zhong.png");
  position: absolute;
  margin-top: 5px;
}

.btn {
  width: 280px;
  height: 42px;
  background-color: #3888F7;
  text-align: center;
  line-height: 42px;
  border-radius: 42px;
}

.line {
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: #3888F7;
}

.tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #3888F7;
  padding: 4px 10px;
  border-radius: 5px;
}

.living {
  background-color: #eb4251;
}
</style>
